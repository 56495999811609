//App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import './main.css';
import './main.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';

import IlanYonetimi from './IlanYonetimi';
import Anasayfa from './Anasayfa';
//import Login from './Login';

function App() {

  // const [girisYapildi, setGirisYapildi] = useState(false);

  // const handleLogin = async (username, password) => {
  //   try {
  //     const response = await fetch('http://88.247.35.130:3001/api/login', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ username, password }),
  //     });
  //     const data = await response.json();
  //     if (data.success) {
  //       setGirisYapildi(true);
  //     } else {
  //       alert('Kullanıcı adı veya şifre hatalı.');
  //     }
  //   } catch (error) {
  //     alert('Giriş yapılırken bir hata oluştu.');
  //   }
  // };
  

  return (
    <Router>
      <Routes>
      <Route path="/" element={<Anasayfa />} />
        <Route path="/admin" element={<IlanYonetimi />} />

      </Routes>

    </Router>
  );
}

export default App;
