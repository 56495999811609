//ANasayfa.js
import React, { useState, useEffect } from 'react';
import './main.css';
import './main.min.css';
//import './bulma.min.css';
import { Card, CardBody, CardHeader, Container, CardImg, CardText, Carousel, Modal, Button, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
//import cardData from './cardData.json'

import ModalImage from "react-modal-image";
import { HiMagnifyingGlassPlus } from "react-icons/hi2";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';

import IlanYonetimi from './IlanYonetimi';

function Anasayfa() {

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const [filter, setFilter] = useState('all');

    const [showModal, setShowModal] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);

    const [showKonut, setShowKonut] = useState(true);
    const [showIsyeri, setShowIsyeri] = useState(true);
    const [showArsa, setShowArsa] = useState(true);

    const toggleKonut = () => setShowKonut(!showKonut);
    const toggleIsyeri = () => setShowIsyeri(!showIsyeri);
    const toggleArsa = () => setShowArsa(!showArsa);

    const [siralamaTuru, setSiralamaTuru] = useState('varsayilan');

    const [ilanlar, setIlanlar] = useState([]);

    const sirala = (data) => {
        if (siralamaTuru === 'azalan') {
            return [...data].sort((a, b) => b.fiyat - a.fiyat);
        } else if (siralamaTuru === 'artan') {
            return [...data].sort((a, b) => a.fiyat - b.fiyat);
        } else {
            // Varsayılan sıralama
            return [...data].sort((a, b) => a.siralama - b.siralama);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchIlanlarVeResimleri = async () => {
            try {
                const res = await fetch('http://88.247.35.130:3001/api/ilanlar');
                const ilanlarData = await res.json();
                const ilanlarWithImages = await Promise.all(ilanlarData.map(async (ilan) => {
                    const resimResponse = await fetch(`http://88.247.35.130:3001/api/ilanlar/${ilan.id}/resimler`);
                    const resimler = await resimResponse.json();
                    return { 
                        ...ilan, 
                        images: resimler.map(img => `data:image/jpeg;base64,${img.data}`) // Artık her resim için img.data kullanılıyor
                    };
                }));
                setIlanlar(ilanlarWithImages);
            } catch (error) {
                console.error('İlanlar ve resimler yüklenirken bir hata oluştu:', error);
            }
        };
    
        fetchIlanlarVeResimleri();
    }, []);
    
    

    const openModal = (images) => {
        setCurrentImages(images);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setCurrentImages([]);
    };

    const filteredData = ilanlar ? ilanlar.filter(card => {
        return (filter === 'all' || card.type === filter) &&
            ((showKonut && card.turu === 1) ||
            (showIsyeri && card.turu === 2) ||
            (showArsa && card.turu === 3)) &&
            card.aktif === true; // Sadece aktif olan ilanları filtrele
    }):[];
    

    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter);
    };


    function formatPrice(price) {
        return new Intl.NumberFormat('tr-TR').format(price);
    }


    return (

        <div className="body">

            {/* <IlanYonetimi /> */}
            <Container fluid >

                <Card className="mt-1 card  ">
                    <CardHeader className="special-header  ">
                        <Row className="h-100">
                            {/* <Col md={3} className="d-flex flex-column justify-content-center align-items-center">
                                <h4 className='card-header-text'>Erkan TOPALOĞLU</h4>
                                <h3 className='card-header-text'>0543 541 03 43</h3>
                            </Col> */}
                            <Col  className="d-flex flex-column justify-content-center align-items-center">
                                <h1 className='card-header-text'>TOPALOĞLU EMLAK</h1>
                                {/* <h5 className='card-header-text'>İNŞAAT & ARAÇ KİRALAMA</h5> */}
                            </Col>


                            {/* <Col md={3} className="d-flex flex-column justify-content-center align-items-center">
                                <h5 className='card-header-text'>Müftü Mahallesi Devrim Bulvarı <br></br> Oğuzlar İş Hanı No:27 Kat:4
                                <br></br>
                                Karadeniz Ereğli / ZONGULDAK
                                
                                </h5>
                            </Col> */}
                        </Row>

                        <Row className="mb-3 mt-3 text-white">

                            <Col className="form-group form-row " style={{ border: '10px', borderColor: "white" }}>
                                <label className={`switch ${isSmallScreen ? 'is-small' : 'is-medium'}  is-rounded card-header-text2`}>
                                
                                    <input type="checkbox" checked={filter === 'all'} onChange={() => handleFilterChange('all')} />
                                    <span className="check is-succes"></span>
                                    <span className="control-label">Tümü</span>
                                </label>

                                <label className={`switch ${isSmallScreen ? 'is-small' : 'is-medium'}  is-rounded card-header-text2`}>
                                    <input type="checkbox" checked={filter === 'rent'} onChange={() => handleFilterChange('rent')} />
                                    <span className="check is-info"></span>
                                    <span className="control-label">Kiralık</span>
                                </label>

                                <label className={`switch ${isSmallScreen ? 'is-small' : 'is-medium'}  is-rounded card-header-text2`}>
                                    <input type="checkbox" checked={filter === 'sale'} onChange={() => handleFilterChange('sale')} />
                                    <span className="check is-warning"></span>
                                    <span className="control-label">Satılık</span>
                                </label>



                            </Col>
                            <Col className="form-group form-row ">
                                <label className={`b-checkbox ${isSmallScreen ? 'is-small' : 'is-medium'}  checkbox card-header-text2`} >
                                    <input type="checkbox" checked={showKonut} onChange={toggleKonut} />
                                    <span className="check is-danger"></span>
                                    <span className="control-label">Konut</span>
                                </label>

                                <label className={`b-checkbox ${isSmallScreen ? 'is-small' : 'is-medium'}  checkbox card-header-text2`}>
                                    <input type="checkbox" checked={showIsyeri} onChange={toggleIsyeri} />
                                    <span className="check is-info"></span>
                                    <span className="control-label">İşyeri</span>
                                </label>

                                <label className={`b-checkbox ${isSmallScreen ? 'is-small' : 'is-medium'}  checkbox card-header-text2`}>
                                    <input type="checkbox" checked={showArsa} onChange={toggleArsa} />
                                    <span className="check is-primary"></span>
                                    <span className="control-label">Arsa</span>
                                </label>
                            </Col>
                            <Col className="form-group form-row ">
                                <label className={`b-radio ${isSmallScreen ? 'is-small' : 'is-medium'}  radio card-header-text2`}>
                                    <input type="radio" name="siralama" onChange={() => setSiralamaTuru('varsayilan')} checked={siralamaTuru === 'varsayilan'} />
                                    <span className="check is-primary"></span>
                                    <span className="control-label">Varsayılan</span>
                                </label>

                                <label className={`b-radio ${isSmallScreen ? 'is-small' : 'is-medium'}  radio card-header-text2`}>
                                    <input type="radio" name="siralama" onChange={() => setSiralamaTuru('azalan')} checked={siralamaTuru === 'azalan'} />
                                    <span className="check is-primary"></span>
                                    <span className="control-label">Azalan </span>
                                </label>

                                <label className={`b-radio ${isSmallScreen ? 'is-small' : 'is-medium'}  radio card-header-text2`}>
                                    <input type="radio" name="siralama" onChange={() => setSiralamaTuru('artan')} checked={siralamaTuru === 'artan'} />
                                    <span className="check is-primary"></span>
                                    <span className="control-label">Artan </span>
                                </label>
                            </Col>

                        </Row>
                        <Row>
                             {/* <Col className="d-flex flex-column justify-content-center align-items-center">
                                <h6 >Erkan TOPALOĞLU - 0543 541 03 43 - Müftü Mahallesi Devrim Bulvarı Oğuzlar İş Hanı No:27 Kat:4 Karadeniz Ereğli / ZONGULDAK</h6>
                               
                            </Col> */}

                             {/* <Col md={3} className="d-flex flex-column justify-content-center align-items-center">
                                <h5 className='card-header-text'>Müftü Mahallesi Devrim Bulvarı <br></br> Oğuzlar İş Hanı No:27 Kat:4
                                <br></br>
                                Karadeniz Ereğli / ZONGULDAK
                                
                                </h5>
                            </Col> */}
                        </Row>
                    </CardHeader>

                    <CardBody className="card-container">
                        {
                        filteredData.length > 0 ? (
                            filteredData.map((card) => (


                            <Card key={card.id} className="mt-1" >
                                <CardHeader
                                    className={`text-white d-flex justify-content-between align-items-center ${card.type === 'sale' ? 'bg-warning' : 'bg-info'}`}
                                >
                                    <h4 className="m-0">{card.type === 'sale' ? 'Satılık' : 'Kiralık'}
                                        {/* {card.turu === 1 ? 'Konut' : (card.turu === 2 ? 'İşyeri' : (card.turu === 3 ? 'Arsa' : ''))} */}
                                    </h4>
                                    <HiMagnifyingGlassPlus size={30} onClick={() => openModal(card.images)} />
                                </CardHeader>
                                <CardBody>
                                <Carousel style={{ height: '210px' }}>
                                    {card.images.map((image, index) => (
                                        <Carousel.Item key={index}>
                                            <img className="d-block w-100" src={image} alt={`İlan Resmi ${index}`} />
                                        </Carousel.Item>
                                    ))}
                </Carousel>
                                    <CardText
                                        className={`d-flex justify-content-between align-items-center ${card.turu === 1 ? 'bg-konut' : (card.turu === 2 ? 'bg-isyeri' : (card.turu === 3 ? 'bg-arsa' : ''))}`}
                                        style={{ fontSize: '1.75rem', marginLeft: '10px' }}
                                    >
                                        &nbsp;&nbsp;{formatPrice(card.fiyat)} TL  {card.turu === 1 ? 'Konut' : (card.turu === 2 ? 'İşyeri' : (card.turu === 3 ? 'Arsa' : ''))}
                                    </CardText>

                                    <CardText>
                                        {card.description}
                                    </CardText>
                                </CardBody>
                            </Card>

))
) : (
    <p>İlan bulunamadı veya veriler yükleniyor...</p>
    )
}
                        
</CardBody>
                </Card>

              
            </Container>


            {/* <Routes>
                <Route path="/" element={<Navigate to="/admin" />} />
                <Route path="/admin" element={<IlanYonetimi />} />
            </Routes> */}

            <Modal show={showModal} onHide={closeModal} size="lg" centered>
                <Modal.Body>
                    <Carousel>
                        {currentImages.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img src={image} alt={`Slide ${index}`} className="d-block w-100" />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>


        </div >

    );
}

export default Anasayfa;
