//IlanYonetimi.js
import React, { useState, useEffect } from 'react';
import IlanEklemeFormu from './IlanEklemeFormu'; // İlan ekleme formu bileşenini import edin
//import cardData from './cardData.json'
import { Card, CardBody, CardHeader, Container, CardImg, CardText, Carousel, Modal, Button, Row, Col } from 'react-bootstrap'
import { HiMagnifyingGlassPlus } from "react-icons/hi2";
//import Login from './Login';


function IlanYonetimi() {
    const [ilanlar, setIlanlar] = useState([]);
    const [seciliIlan, setSeciliIlan] = useState(null);

    const [showKonut, setShowKonut] = useState(true);
    const [showIsyeri, setShowIsyeri] = useState(true);
    const [showArsa, setShowArsa] = useState(true);
    const [currentImages, setCurrentImages] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const [ilanTipi, setIlanTipi] = useState('sale');
    const [siralamaTuru, setSiralamaTuru] = useState('varsayilan');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    //const sortedData = sirala(ilanlar);
    const [filter, setFilter] = useState('all');

    const [ilanlarYenilendi, setIlanlarYenilendi] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loginError, setLoginError] = useState('');

    const authenticateUser = () => {
        if (username === "admin" && password === "sar1788gu") {
            setIsAuthenticated(true);
        } else {
            setLoginError("Kullanıcı adı veya şifre hatalı.");
        }
    };
    
    // useEffect(() => {
    //     // cardData.json'dan verileri yükle
    //     fetchIlanlar();
    // }, []);

    useEffect(() => {
        // ilanlar state'i güncellendiğinde, her ilanın mevcut açıklamasını editingDescription içinde sakla
        const initialDescriptions = ilanlar.reduce((acc, ilan) => {
            acc[ilan.id] = ilan.description;
            return acc;
        }, {});
        setEditingDescription(initialDescriptions);
    }, [ilanlar]);

    useEffect(() => {
        const fetchResimler = async (ilanId) => {
            const res = await fetch(`http://88.247.35.130:3001/api/ilanlar/${ilanId}/resimler`);
            const data = await res.json();
            return data; // Base64 kodlanmış resim stringlerini döndür
        };
        

        const fetchIlanlarVeResimleri = async () => {
            setLoading(true);
            try {
                const res = await fetch('http://88.247.35.130:3001/api/ilanlar');
                const ilanlarData = await res.json();
                const ilanlarWithImages = await Promise.all(ilanlarData.map(async (ilan) => {
                    const resimler = await fetchResimler(ilan.id);
                    return { ...ilan, images: resimler };
                }));
                setIlanlar(ilanlarWithImages);
            } catch (error) {
                console.error('İlanlar ve resimler yüklenirken bir hata oluştu:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchIlanlarVeResimleri();
    }, [ilanlarYenilendi]);




    const fetchIlanlar = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await fetch('http://88.247.35.130:3001/api/ilanlar');
            if (!response.ok) {
                throw new Error('Veri alınamadı');
            }
            const data = await response.json();
            setIlanlar(data);
        } catch (error) {
            console.error('İlanlar yüklenirken bir hata oluştu:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // const handleLogin = async (username, password) => {
    //     // Burada sunucuya istek gönderme işlemini yapın
    //     // Örnek: const response = await fetch('/api/login', { ... });
    //     // Şimdilik basit bir kontrol yapalım
    //     if (username === "admin" && password === "12345") {
    //       setLoggedIn(true);
    //       return true;
    //     }
    //     return false;
    //   };


    const handleDurumDegistir = async (ilanId) => {
        const guncellenmisIlan = ilanlar.find(ilan => ilan.id === ilanId);
        if (!guncellenmisIlan) return;

        const yeniDurum = !guncellenmisIlan.aktif;
        guncellenmisIlan.aktif = yeniDurum; // Yerel durumu güncelle

        try {
            const response = await fetch(`http://88.247.35.130:3001/api/ilanlar/durum/${ilanId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ aktif: yeniDurum }),
            });

            if (!response.ok) {
                throw new Error('Durum güncellenemedi');
            }

            fetchIlanlar(); // İlan listesini yeniden yükle
        } catch (error) {
            console.error('Durum güncellenirken bir hata oluştu:', error);
        }
        await ilanVeResimleriniGuncelle(ilanId);
    };



    const handleIlanTipiChange = async (id, yeniTip) => {
        const guncellenmisIlan = ilanlar.find(ilan => ilan.id === id);
        if (!guncellenmisIlan) return;

        guncellenmisIlan.type = yeniTip;

        try {
            const response = await fetch(`http://88.247.35.130:3001/api/ilanlar/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(guncellenmisIlan),
            });

            if (!response.ok) {
                throw new Error('İlan güncellenemedi');
            }

            fetchIlanlar();
        } catch (error) {
            console.error('İlan güncellenirken bir hata oluştu:', error);
        }
        await ilanVeResimleriniGuncelle(id);
    };
    const handleTuruDegistir = async (id, yeniTuru) => {
        try {
            const response = await fetch(`http://88.247.35.130:3001/api/ilanlar/turu/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ turu: yeniTuru }),
            });

            if (!response.ok) {
                throw new Error('Tür güncellenemedi');
            }

            fetchIlanlar(); // İlan listesini yeniden yükle
        } catch (error) {
            console.error('Tür güncellenirken bir hata oluştu:', error);
        }
        await ilanVeResimleriniGuncelle(id);
    };

    const handleFiyatDegistir = async (id, yeniFiyat) => {
        // Fiyatı sayısal bir değere dönüştür
        const fiyat = Number(yeniFiyat);
        if (isNaN(fiyat)) {
            alert("Lütfen geçerli bir fiyat girin.");
            return;
        }

        try {
            const response = await fetch(`http://88.247.35.130:3001/api/ilanlar/fiyat/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fiyat: fiyat }),
            });

            if (!response.ok) {
                throw new Error('Fiyat güncellenemedi');
            }

            fetchIlanlar(); // İlan listesini yeniden yükle
        } catch (error) {
            console.error('Fiyat güncellenirken bir hata oluştu:', error);
        }
        await ilanVeResimleriniGuncelle(id);
    };

    // Açıklama metni değişikliklerini yerel durumda tutmak için
    const [editingDescription, setEditingDescription] = useState({});

    const handleDescriptionChange = (id, newDescription) => {
        setEditingDescription(prev => ({ ...prev, [id]: newDescription }));
    };

    const handleDescriptionUpdate = async (id) => {
        // `editingDescription` state'inden yeni açıklama metnini al
        const newDescription = editingDescription[id];
        if (newDescription === undefined) return;  // Eğer açıklama yoksa güncelleme yapma

        try {
            const response = await fetch(`http://88.247.35.130:3001/api/ilanlar/description/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ description: newDescription }),
            });

            if (!response.ok) {
                throw new Error('Açıklama güncellenemedi');
            }

            // İlan listesini yeniden yükle
            fetchIlanlar();
            console.log('Açıklama başarıyla güncellendi.');

            // Güncelleme sonrası `editingDescription` state'ini temizleme
            setEditingDescription(prev => ({ ...prev, [id]: '' }));
        } catch (error) {
            console.error('Açıklama güncellenirken bir hata oluştu:', error);
        }
        await ilanVeResimleriniGuncelle(id);
    };



    // Resim yükleme fonksiyonunu güncelleyin
    const handleImageUpload = async (ilanId, event) => {
        const files = event.target.files;
        const formData = new FormData();

        formData.append('ilanId', ilanId); // İlan ID'sini FormData'ya ekleyin

        for (let i = 0; i < files.length; i++) {
            formData.append('images[]', files[i]);
        }

        // İsteğe ilan ID'si veya diğer bilgileri de ekleyebilirsiniz
        // Örneğin: formData.append('ilanId', ilanId);

        try {
            const response = await fetch('http://88.247.35.130:3001/api/ilanlar/uploadImages', {
                method: 'POST',
                body: formData, // Burada FormData nesnesini gönderiyoruz
                // Fetch API ile multipart/form-data contentType belirtmeye gerek yoktur; otomatik olarak ayarlanır
            });

            if (!response.ok) {
                throw new Error('Resimler yüklenirken bir hata oluştu');
            }

            console.log('Resimler başarıyla yüklendi');
            // Burada başarılı yükleme sonrası yapılacak işlemleri ekleyebilirsiniz
        } catch (error) {
            console.error(error);
        }

        await ilanVeResimleriniGuncelle(ilanId);
    };


    const ilanVeResimleriniGuncelle = async (ilanId) => {
        // ...
        setIlanlarYenilendi(prev => !prev); // ilanlarYenilendi durumunu değiştir
    };

   // Resim Silme Fonksiyonu
   const handleDeleteImage = async (ilanId, resimId) => {
    debugger;
    try {
        const response = await fetch(`http://88.247.35.130:3001/api/ilanlar/resimler/${resimId}`, {
            method: 'DELETE',
        });

        if (response.ok) {
            console.log('Resim başarıyla silindi.');
            ilanVeResimleriniGuncelle(ilanId);
        } else {
            console.error('Resim silinirken bir hata oluştu.');
        }
    } catch (error) {
        console.error('Sunucu ile iletişim kurulurken bir hata oluştu:', error);
    }
};




    if (loading) {
        return <div>Veriler yükleniyor...</div>;
    }

    if (error) {
        return <div>Hata: {error}</div>;
    }

    if (!loggedIn) {
     //   return <Login onLogin={handleLogin} />;
      }

    return (
        <div className="body">

{!isAuthenticated && (
            <div>
                <Card>
                    <CardHeader>
                    <h2>Giriş Yap</h2>
                    </CardHeader>
                    <CardBody>
                    {loginError && <p>{loginError}</p>}
                <form onSubmit={(e) => {
                    e.preventDefault();
                    authenticateUser();
                }}>
                    <label>
                        Kullanıcı Adı: 
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </label>
                    
                    <label>
                        Şifre:
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label>
                   
                    <button type="submit">Giriş Yap</button>
                </form>
                    </CardBody>
                </Card>
               
               
            </div>
        )}

{isAuthenticated && (
            <Container fluid >

                <Card className="mt-1 card  ">
                    <CardBody className="card-container">
                        {ilanlar.map((card) => (
                            <Card key={card.id} className="mt-1" >
                                <CardHeader
                                    className={`text-white d-flex justify-content-between align-items-center ${card.type === 'sale' ? 'bg-warning' : 'bg-info'}`}
                                >
                                    {/* <h4 className="m-0">{card.type === 'sale' ? 'Satılık' : 'Kiralık'} */}
                                    {/* {card.turu === 1 ? 'Konut' : (card.turu === 2 ? 'İşyeri' : (card.turu === 3 ? 'Arsa' : ''))} */}
                                    {/* </h4> */}
                                    <input
                                        type="radio"
                                        value="sale"
                                        checked={card.type === 'sale'}
                                        onChange={() => handleIlanTipiChange(card.id, 'sale')}
                                    /> Satılık
                                    <input
                                        type="radio"
                                        value="rent"
                                        checked={card.type === 'rent'}
                                        onChange={() => handleIlanTipiChange(card.id, 'rent')}
                                    /> Kiralık

                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={card.aktif}
                                            onChange={() => handleDurumDegistir(card.id)}
                                        /> Aktif
                                    </div>
                                    {/* <HiMagnifyingGlassPlus size={30} onClick={() => openModal(card.images)} /> */}
                                </CardHeader>
                                <CardBody>
                                    <input type="file" name="images[]" multiple onChange={(e) => handleImageUpload(card.id, e)} />

                                    <Carousel style={{ height: '210px' }}>
                                        {(card.images || []).map((image, index) => (
                                            <Carousel.Item key={image.id}>
                                                
                                            <img
                                                className="d-block w-100"
                                                src={`data:image/jpeg;base64,${image.data}`}
                                                alt={`İlan Resmi ${image.id}`}
                                               
                                            />
                                            
                                            <button onClick={() => handleDeleteImage(card.id, image.id)}>Sil</button>
                                        </Carousel.Item>
                                        ))}
                                    </Carousel>






                                    <CardText
                                        className={`d-flex justify-content-between align-items-center ${card.turu === 1 ? 'bg-konut' : (card.turu === 2 ? 'bg-isyeri' : (card.turu === 3 ? 'bg-arsa' : ''))}`}
                                        style={{ fontSize: '1.75rem', marginLeft: '10px' }}
                                    >
                                        <input
                                            type="text"
                                            value={card.fiyat}
                                            onChange={(e) => handleFiyatDegistir(card.id, e.target.value)}
                                            className={` ${card.turu === 1 ? 'bg-konut' : (card.turu === 2 ? 'bg-isyeri' : (card.turu === 3 ? 'bg-arsa' : ''))}`}
                                            style={{ width: '135px', marginRight: '0px', borderColor: 'transparent', }}
                                        />  TL

                                        <select
                                            value={card.turu}
                                            onChange={(e) => handleTuruDegistir(card.id, e.target.value)}
                                            className="form-select form-select-sm"
                                            style={{ width: 'auto' }}
                                        >
                                            <option value="1">Konut</option>
                                            <option value="2">İşyeri</option>
                                            <option value="3">Arsa</option>
                                        </select>
                                    </CardText>

                                    <CardText>
                                        <textarea
                                            value={editingDescription[card.id] || ''}
                                            onChange={(e) => handleDescriptionChange(card.id, e.target.value)}
                                            className="form-control"
                                            rows="2"
                                        ></textarea>
                                        <button onClick={() => handleDescriptionUpdate(card.id)} className="btn btn-primary mt-2">
                                            Güncelle
                                        </button>

                                    </CardText>
                                </CardBody>
                            </Card>
                        ))}
                    </CardBody>
                </Card>
            </Container >
             )}
        </div >
    );
}

export default IlanYonetimi;
